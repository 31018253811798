@use '../../styles/yr' as *;

.graph-row {
  display: flex;
  position: relative;
}

.graph-row__column {
  position: absolute;
  display: flex;

  // We use transform and top to center the content here
  // since IE does not seem to support align-items properly when we use position:absolute aswell
  top: 50%;
  transform: translateY(-50%);
}

.graph-row__tick {
  display: flex;
  position: absolute;
  transform: translateX(-50%);
}
