@use "sass:map";
@use '../../styles/yr' as *;

.graph-legend-new {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.graph-legend-new__icon {
  margin-right: size(0.5);
  height: rem(10px);
  width: rem(10px);
  position: relative;
}

.graph-legend-new[data-type='plain-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-text-subtle);
  }
}

.graph-legend-new[data-type='normal-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-temperature-normal);
  }
}

.graph-legend-new[data-type='missing-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-no-data-stroke);
  }
}

.graph-legend-new[data-type='missing-bar'] {
  .graph-legend-new__background {
    fill: var(--color-graph-no-data-bg);
  }
  .graph-legend-new__foreground {
    stroke: var(--color-graph-no-data-stroke);
  }
}

.graph-legend-new[data-type='dew-point-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-temperature-dew-point);
  }
}

.graph-legend-new[data-type='temperature-probability'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-minus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-minus);
    }
  }

  .graph-legend-new__icon[data-id='1'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-plus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-plus);
    }
  }
}

.graph-legend-new[data-type='temperature-probability-warm'] {
  .graph-legend-new__background {
    fill: var(--color-graph-temperature-probability-bg-plus);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-temperature-probability-stroke-plus);
  }
}

.graph-legend-new[data-type='temperature-probability-cold'] {
  .graph-legend-new__background {
    fill: var(--color-graph-temperature-probability-bg-minus);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-temperature-probability-stroke-minus);
  }
}

.graph-legend-new[data-type='temperature-probability-twenty-one-day-forecast'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-minus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-minus);
    }
  }

  .graph-legend-new__icon[data-id='1'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-plus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-plus);
    }
  }
}

.graph-legend-new[data-type='temperature-probability-twenty-one-day-forecast-only-warm'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-plus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-plus);
    }
  }
}

.graph-legend-new[data-type='temperature-probability-twenty-one-day-forecast-only-cold'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__background {
      fill: var(--color-graph-temperature-probability-bg-minus);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-temperature-probability-stroke-minus);
    }
  }
}

.graph-legend-new[data-type='temperature-expected-twenty-one-day-forecast'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__bar {
      fill: var(--color-graph-temperature-temp-plus);
    }
  }

  .graph-legend-new__icon[data-id='1'] {
    .graph-legend-new__bar {
      fill: var(--color-graph-temperature-temp-minus);
    }
  }
}

.graph-legend-new[data-type='temperature-expected-twenty-one-day-forecast-only-warm'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__bar {
      fill: var(--color-graph-temperature-temp-plus);
    }
  }
}

.graph-legend-new[data-type='temperature-expected-twenty-one-day-forecast-only-cold'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__bar {
      fill: var(--color-graph-temperature-temp-minus);
    }
  }
}

.graph-legend-new[data-type='temperature-curve'] {
  .graph-legend-new__line:nth-child(1) {
    stroke: var(--color-graph-temperature-temp-plus);
  }

  .graph-legend-new__line:nth-child(2) {
    stroke: var(--color-graph-temperature-temp-minus);
  }
}

.graph-legend-new[data-type='temperature-curve-warm'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-temperature-temp-plus);
  }
}

.graph-legend-new[data-type='temperature-curve-cold'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-temperature-temp-minus);
  }
}

.graph-legend-new[data-type='mean-temperature-curve'] {
  .graph-legend-new__line:nth-child(1) {
    stroke: var(--color-graph-temperature-temp-plus);
  }

  .graph-legend-new__line:nth-child(2) {
    stroke: var(--color-graph-temperature-temp-minus);
  }
}

.graph-legend-new[data-type='measured-temperature-curve'] {
  .graph-legend-new__line:nth-child(1) {
    stroke: var(--color-graph-temperature-temp-plus);
  }

  .graph-legend-new__line:nth-child(2) {
    stroke: var(--color-graph-temperature-temp-minus);
  }
}

.graph-legend-new[data-type='water-temperature-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-temperature-temp-plus);
  }
}

.graph-legend-new[data-type='temperature-bars'] {
  .graph-legend-new__icon {
    opacity: 0.5;
  }

  .graph-legend-new__bar:nth-child(1) {
    fill: var(--color-graph-temperature-temp-plus);
  }

  .graph-legend-new__bar:nth-child(2) {
    fill: var(--color-graph-temperature-temp-minus);
  }
}

.graph-legend-new[data-type='precipitation-bar'] {
  .graph-legend-new__bar {
    fill: var(--color-graph-precipitation-expected);
  }
}

.graph-legend-new[data-type='precipitation-min-bar'] {
  .graph-legend-new__bar {
    fill: var(--color-graph-precipitation-expected);
  }
}

.graph-legend-new[data-type='precipitation-max-bar'] {
  .graph-legend-new__background {
    fill: var(--color-graph-precipitation-probability-bg);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-precipitation-probability-stroke);
  }
}

.graph-legend-new[data-type='some-precipitation'] {
  .graph-legend-new__bar {
    fill: var(--color-graph-precipitation-probability-some);
  }
}

.graph-legend-new[data-type='heavy-precipitation'] {
  .graph-legend-new__bar {
    fill: var(--color-graph-precipitation-probability-heavy);
  }
}

.graph-legend-new[data-type='pressure-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-pressure-pressure);
  }
}

.graph-legend-new[data-type='humidity-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-humidity-humidity);
  }
}

.graph-legend-new[data-type='wind-probability'] {
  .graph-legend-new__background {
    fill: var(--color-graph-wind-probability-bg);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-wind-probability-stroke);
  }
}

.graph-legend-new[data-type='wind-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wind-wind);
  }
}
.graph-legend-new[data-type='mean-wind-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wind-wind);
  }
}

.graph-legend-new[data-type='max-wind-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wind-wind);
  }
}

.graph-legend-new[data-type='wind-gust-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wind-wind);
  }
}

.graph-legend-new[data-type='wind-max-gust-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wind-wind);
  }
}

.graph-legend-new[data-type='snow-depth-bar'] {
  .graph-legend-new__bar {
    fill: var(--color-graph-snowdepth-history-bg);
  }
}

.graph-legend-new[data-type='air-quality-severity-low-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-air-quality-low);
  }
}

.graph-legend-new[data-type='air-quality-severity-moderate-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-air-quality-moderate);
  }
}

.graph-legend-new[data-type='air-quality-severity-severe-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-air-quality-high);
  }
}

.graph-legend-new[data-type='air-quality-severity-extreme-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-air-quality-very-high);
  }
}

.graph-legend-new[data-type='wave-height-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-wave-height-wave-height);
  }
}

.graph-legend-new[data-type='sea-current-curve'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-sea-current-sea-current);
  }
}

.graph-legend-new[data-type='water-level-prediction'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-sea-level-predicted);
  }
}

.graph-legend-new[data-type='water-level-forecast'] {
  .graph-legend-new__line {
    stroke: var(--color-graph-sea-level-forecast);
  }
}

.graph-legend-new[data-type='cloud-cover-high'] {
  .graph-legend-new__background {
    fill: var(--color-graph-cloudcover-background-high);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-cloudcover-stroke-clouds);
  }
}

.graph-legend-new[data-type='cloud-cover-middle'] {
  .graph-legend-new__background {
    fill: var(--color-graph-cloudcover-background-middle);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-cloudcover-stroke-clouds);
  }
}

.graph-legend-new[data-type='cloud-cover-low'] {
  .graph-legend-new__background {
    fill: var(--color-graph-cloudcover-background-low);
  }

  .graph-legend-new__foreground {
    stroke: var(--color-graph-cloudcover-stroke-clouds);
  }
}

.graph-legend-new[data-type='cloud-cover-fog'] {
  .graph-legend-new__background {
    fill: var(--color-graph-cloudcover-background-fog);
  }

  .graph-legend-new__foreground {
    fill: var(--color-graph-cloudcover-stroke-fog);
  }
}

.graph-legend-new[data-type='cloud-cover'] {
  .graph-legend-new__icon[data-id='0'] {
    .graph-legend-new__background {
      fill: var(--color-graph-cloudcover-background-high);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-cloudcover-stroke-clouds);
    }
  }

  .graph-legend-new__icon[data-id='1'] {
    .graph-legend-new__background {
      fill: var(--color-graph-cloudcover-background-middle);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-cloudcover-stroke-clouds);
    }
  }

  .graph-legend-new__icon[data-id='2'] {
    .graph-legend-new__background {
      fill: var(--color-graph-cloudcover-background-low);
    }

    .graph-legend-new__foreground {
      stroke: var(--color-graph-cloudcover-stroke-clouds);
    }
  }

  .graph-legend-new__icon[data-id='3'] {
    .graph-legend-new__background {
      fill: var(--color-graph-cloudcover-background-fog);
    }

    .graph-legend-new__foreground {
      fill: var(--color-graph-cloudcover-stroke-fog);
    }
  }
}

.graph-legend-new[data-type='aurora-forecast'] {
  .graph-legend-new__gradient-start {
    stop-color: var(--color-graph-aurora-gradient-start);
  }

  .graph-legend-new__gradient-stop {
    stop-color: var(--color-graph-aurora-gradient-stop);
  }
}
