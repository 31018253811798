@use '../../styles/yr' as *;

.graph-x-axis-row__column {
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.graph-x-axis-row__column--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.graph-x-axis-row__column--align-left {
  text-align: left;
}

.graph-x-axis-row__column--align-right {
  text-align: right;
}
