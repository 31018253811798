@use '../../styles/yr' as *;

.graph-temperature-gradient__stop-warm-default {
  stop-color: var(--color-graph-temperature-temp-plus);
}

.graph-temperature-gradient__stop-cold-default {
  stop-color: var(--color-graph-temperature-temp-minus);
}

.graph-temperature-gradient__stop-warm-lightened {
  stop-color: var(--color-graph-temperature-history-bar-bg-plus);
}

.graph-temperature-gradient__stop-cold-lightened {
  stop-color: var(--color-graph-temperature-history-bar-bg-minus);
}
