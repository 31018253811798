@use '../../styles/yr' as *;

.graph-legend-row {
  display: flex;
  align-items: center;
  padding-top: size(1);
  gap: 0 size(2);
  flex-wrap: wrap;
}

.graph-legend-row--small {
  padding-top: 0;
}
