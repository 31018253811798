@use '../../styles/yr' as *;

.graph-hover-panel {
  border-collapse: collapse;
}

.graph-hover-panel__row th,
.graph-hover-panel__row td {
  padding-top: size(1);
}

.graph-hover-panel__row--is-new-row th,
.graph-hover-panel__row--is-new-row td {
  padding-top: size(2);
}

.graph-hover-panel__row-heading {
  text-align: left;
}

.graph-hover-panel__type {
  font-weight: $typography-font-weight-normal;
  min-width: 8em;
  text-align: left;
}

.graph-hover-panel__data {
  padding: 0 0 0 1em;
  text-align: right;
}

.graph-hover-panel__data--empty {
  text-align: center;
}

.graph-hover-panel__time {
  text-align: right;
}

.graph-hover-panel__row-content {
  width: 150%;
  padding-top: 1em;
}

.graph-hover-panel__divider {
  border-bottom: $divider-solid;
  height: rem(18px);
}
