@use '../../styles/yr' as *;

.hover-panel {
  background-color: var(--color-background-elevated);
  border-radius: 4px;
  box-shadow: $theme-box-shadow-elevated;
  opacity: 0;
  padding: size(2);
  position: fixed;
  z-index: $z-index-popover;
}

.hover-panel--visible {
  opacity: 1;
}

.hover-panel__title {
  margin-bottom: size(1);
}
